export function journalLink(item) {
  let resolved = {};
  if (item.isToOJS) {
    resolved["href"] = `https://ojs.acad-pub.com/index.php/${item.path}`;
  } else {
    resolved = this.$router.resolve({
      path: "/detailPages/" + item.path,
      query: {
        journal: item.path,
      },
    });
  }
  return resolved.href;
}
export function articleLink(item) {
  let resolved = {};
  if (item.isToOJS) {
    resolved[
      "href"
    ] = `https://ojs.acad-pub.com/index.php/${item.journalPath}/article/view/${item.id}`;
  } else {
    resolved = this.$router.resolve({
      path: "/detailPages/" + item.journalPath + "/articalDetail",
      query: {
        journal: item.journalPath,
        id: item.id,
      },
    });
  }
  return resolved.href;
}
export function newsLink(item) {
  const resolved = this.$router.resolve({
    name: "announcementsDetail",
    query: {
      detailUrl: item.url,
    },
  });
  return resolved.href;
}
export function announcementsLink(item) {
  const resolved = this.$router.resolve({
    path:
      "/detailPages/" +
      this.$route.query.journal +
      "/journalAnnouncementsDetail",
    query: {
      journal: this.$route.query.journal,
      id: item.id,
    },
  });
  return resolved.href;
}
