<template>
  <div class="header" v-show="isShow">
    <div class="header-center">
      <div class="logo">
        <a class="logo-img" title="www.acad-pub.com">
          <img :src="imgUrl" alt="" />
        </a>
      </div>
      <div class="nav">
        <ul class="nav-tabs">
          <li
            v-for="(item, index) in filteredNavList"
            :key="index"
            :class="{ select: item.path == $route.path }"
          >
            <router-link :to="item.path">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="header-right">
        <div class="icon-search" @click="toSearch">
          <i class="iconfont icon-sousuo"></i>
        </div>
        <!-- <a class="submit" @click="toSubmit"> submit</a> -->
        <div>
          <el-dropdown
            @command="handleCommand"
            v-if="userName !== ''"
            class="user-style"
          >
            <span class="el-dropdown-link">
              <img src="@/assets/opimg/r1.png" alt="" />
              {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item command="Dashboard">Dashboard</el-dropdown-item> -->
              <el-dropdown-item command="profile"
                >View Profile</el-dropdown-item
              >
              <el-dropdown-item command="Manuscript"
                >View Manuscript</el-dropdown-item
              >
              <el-dropdown-item command="logout">Logout</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <a class="user-style" @click="toLogin" v-else>
            <img src="@/assets/opimg/r1.png" alt="" />
            LOGIN
          </a>
          <a v-if="userName === ''" class="user-style" @click="toRegister">
            <img src="@/assets/opimg/r2.png" alt="" />
            REGISTER
          </a>
        </div>
        <div
          id="menu"
          :class="{ navMenu: !isShowMenu, minNnavShow: isShowMenu }"
          @click="minNavShow"
        >
          <span class="a1"></span>
          <span class="a2"></span>
          <span class="a3"></span>
        </div>
        <div class="min-nav" v-if="isShowMenu">
          <!-- v-if="isMobile" -->
          <ul>
            <li
              @click="menuShow"
              v-for="(item, index) in navList"
              :key="index"
              :class="{ select: item.path == $route.path }"
            >
              <router-link :to="item.path">{{ item.name }}</router-link>
            </li>
            <!-- <li @click="toSubmits">submit</li> -->
          </ul>
        </div>
      </div>
    </div>
    <!-- <Login></Login> -->
  </div>
</template>

<script>
import { get, post } from "@/utils/http";
// import service from "@/utils/service";
export default {
  data() {
    return {
      imgUrl: "",
      isShow: true,
      dialogVisible: false,
      userName: "",
      isShowMenu: false,
      navList: [
        { name: "Home", path: "/", show: true },
        { name: "Journals", path: "/journalList", show: true },
        {
          name: "Editorial and Publishing Policies",
          path: "/editorialPublishing",
          show: true,
        },
        // { name: "Partners", path: "/" },
        {
          name: "News & Announcements",
          path: "/announcementsList",
          show: true,
        },
        { name: "About", path: "/aboutView", show: true },
        { name: "For authors", path: "/forAuthors", show: false },
        { name: "For reviewers", path: "/forReviewers", show: false },
        { name: "For editors", path: "/forEditors", show: false },
      ],
    };
  },
  computed: {
    filteredNavList() {
      return this.navList.filter((item) => item.show);
    },
  },
  created() {},
  mounted() {
    this.userName = JSON.parse(localStorage.getItem("userInfo"))
      ? JSON.parse(localStorage.getItem("userInfo")).userName
      : "";
    this.getInfo();
  },
  methods: {
    menuShow() {
      this.isShowMenu = false;
    },
    minNavShow() {
      this.isShowMenu = !this.isShowMenu;
    },
    toSearch() {
      this.$router.push({
        path: "/advancedSearch",
      });
    },
    toRegister() {
      this.$router.push({
        path: "/regist",
      });
    },
    toLogin() {
      this.$router.push({
        path: "/login",
      });
    },
    toSubmits() {
      if (this.userName === "") {
        this.$router.push({
          path: "/login",
        });
      } else {
        if (this.$route.query.journal) {
          let rolesList = JSON.parse(localStorage.getItem("rolesList"));
          let filterRole = rolesList.findIndex(
            (item) =>
              item.path === this.$route.query.journal && item.authorChecked
          );
          if (filterRole !== -1) {
            this.$router.push({
              path:
                "/detailPages/" + this.$route.query.journal + "/submissions",
              query: {
                journal: this.$route.query.journal,
              },
            });
          } else {
            this.$router.push({
              path: "/detailPages/" + this.$route.query.journal + "/wizard",
              query: {
                journal: this.$route.query.journal,
              },
            });
          }
        } else {
          this.$router.push({
            path: "/journalList",
          });
        }
      }
      this.isShowMenu = false;
    },
    toSubmit() {
      if (this.userName === "") {
        this.$router.push({
          path: "/login",
        });
      } else {
        if (this.$route.query.journal) {
          let rolesList = JSON.parse(localStorage.getItem("rolesList"));
          let filterRole = rolesList.findIndex(
            (item) =>
              item.path === this.$route.query.journal && item.authorChecked
          );
          if (filterRole !== -1) {
            this.$router.push({
              path:
                "/detailPages/" + this.$route.query.journal + "/submissions",
              query: {
                journal: this.$route.query.journal,
              },
            });
          } else {
            this.$router.push({
              path: "/detailPages/" + this.$route.query.journal + "/wizard",
              query: {
                journal: this.$route.query.journal,
              },
            });
          }
        } else {
          this.$router.push({
            path: "/journalList",
          });
        }
      }
    },
    getInfo() {
      get("qq/api/v1/site").then((res) => {
        this.imgUrl = res.data.logo.url;
      });
    },
    logout() {
      let token = localStorage.getItem("token");
      post("/index/api/v1/access/logout?apiToken=" + token).then((res) => {
        if (res.code === 200) {
          this.$router.push({
            path: "/",
          });
          localStorage.clear();
          this.userName = "";
        }
      });
    },
    handleCommand(command) {
      switch (command) {
        case "Dashboard":
          // https://ojs.acad-pub.com/
          this.toOjs();
          // this.$router.push({ path: "/profile" });
          break;
        case "profile":
          this.toOjs();
          // window.open("https://ojs.acad-pub.com/", "_blank");
          // this.$router.push({ path: "/profile" });
          break;
        case "Manuscript":
          this.$router.push({
            path: "/manuscript",
            query: {
              journal: "index",
            },
          });
          break;
        case "logout":
          this.logout();
          break;
      }
    },
    toOjs() {
      let journal = this.$route.query.journal
        ? this.$route.query.journal
        : "index";
      let token = localStorage.getItem("token");
      get(journal + "/api/v1/access/ojs?apiToken=" + token).then((res) => {
        if (res.code === 200) {
          window.open(res.data, "_blank");
        } else if (
          res.code === 401 &&
          res.messages == "The user not allow access."
        ) {
          this.$router.push({ path: "/profile" });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "@/assets/iconfont/iconfont.css";
.header {
  width: 100%;
  background-color: #ffffff;
  height: 75px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  box-shadow: 4px 4px 4px rgba(195, 195, 195, 0.35);
  @media (max-width: 996px) {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 5px;
    box-sizing: border-box;
  }
  .header-center {
    width: 1400px;
    margin: auto;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    @media (max-width: 996px) {
      width: 100vw;
    }
    .logo {
      .logo-img {
        height: 65px;
        padding-right: 10px;
        img {
          height: 65px;
          width: auto;
          transition: all 0.5s;
        }
      }
    }
    .nav {
      flex: 1;
      .nav-tabs {
        // width: 100%;
        display: flex;
        justify-content: space-between;
        // float: left;
        padding: 0 30px;
        @media (max-width: 996px) {
          // width: 100%;
          display: none;
        }
        li {
          list-style: none;
          cursor: pointer;
          height: 65px;
          font-size: 16px;
          line-height: 65px;
          position: relative;
          // font-weight: bold;
          font-family: "Arial", "Helvetica", sans-serif; /* 设置字体为黑体 */
        }
        li:after {
          content: "";
          width: 0;
          height: 3px;
          background-color: #a40000;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: all 0.5s;
        }
        li:hover:after {
          width: 100%;
        }
        li:hover {
          color: #a40000;
        }
        .select {
          color: #a40000;
        }
        .select:after {
          width: 100%;
        }
      }
    }

    .navMenu {
      display: none;
      width: 30px;
      position: relative;
      @media (max-width: 996px) {
        display: block;
      }
      @media (min-width: 997px) {
        display: none;
      }
      span {
        background-color: #004fa3;
        display: block;
        width: 30px;
        height: 2px;
        position: absolute;
        transition: all 0.5s;
      }
      .a1 {
        top: -10px;
        left: 0;
      }
      .a2 {
        display: block;
        top: -2px;
        left: 0;
      }
      .a3 {
        top: 6px;
        left: 0;
      }
    }
    .minNnavShow {
      width: 30px;
      position: relative;
      z-index: 3000;
      @media (min-width: 997px) {
        display: none;
      }
      span {
        height: 2px;
        background-color: #004fa3;
        display: block;
        position: absolute;
        transition: all 0.5s;
      }
      .a1 {
        top: -1px;
        width: 30px;
        transform: rotate(45deg);
      }
      .a2 {
        display: none;
      }
      .a3 {
        top: -1px;
        width: 30px;
        transform: rotate(135deg);
      }
    }
    .min-nav {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 75px;
      left: 0;
      background-color: #ffffff;
      transform: all 0.5s;
      z-index: 999;
      @media (min-width: 997px) {
        display: none;
      }
      li {
        width: 100%;
        list-style: none;
        height: 50px;
        font-size: 16px;
        line-height: 50px;
        font-weight: bold;
        text-align: center;
        &:hover {
          background-color: #f19600;
        }
        &:active {
          background-color: #f19600;
        }
      }
    }
    .header-right {
      display: flex;
      align-items: center;
      padding-left: 10px;
      .icon-search {
        cursor: pointer;
        padding-right: 15px;
        .iconfont {
          color: #255d42;
          font-size: 23px;
          font-weight: bold;
        }
      }
      .submit {
        margin: 0 25px;
        background: #a40000;
        color: #fff;
        line-height: 40px;
        border-radius: 20px;
        width: 100px;
        text-align: center;
        cursor: pointer;
        @media (max-width: 996px) {
          display: none;
        }
      }
      .user-style {
        padding-right: 20px;
        font-size: 14px;
        line-height: 25px;
        cursor: pointer;
        display: block;
        &:hover {
          color: #f19600;
        }
      }
    }
  }
}
</style>
<style>
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #f19600 !important;
  color: #fff !important;
}
</style>
